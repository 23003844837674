import { BaseService } from '@/services/base'

import { convertToFlexibleDocumentCollectionItem, convertToFlexibleDocumentItem } from '@/helpers/FlexibleDocument'

import { FlexibleDocumentResource, IFlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentItemResource }                        from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { FlexibleDocumentItemCollectionResource }              from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'
import {
  FlexibleDocumentDropdownOptionResource,
  IFlexibleDocumentDropdownOptionResource
}                                                              from '@/models/flexibleDocument/FlexibleDocumentDropdownOptionResource'
import { FlexibleDocumentComponentCollectionResource }         from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'
import {
  FlexibleDocumentMetaSectionResource,
  IFlexibleDocumentMetaSectionResource
}                                                              from '@/models/flexibleDocument/FlexibleDocumentMetaSectionResource'
import {
  FlexibleDocumentMetaResource,
  IFlexibleDocumentMetaResource
}                                                              from '@/models/flexibleDocument/flexibleDocumentMetaResource'

import { FlexibleDocumentUpdateRequest }               from '@/requests/flexibleDocument/FlexibleDocumentUpdateRequest'
import { FlexibleDocumentCreateItemRequest }           from '@/requests/flexibleDocument/FlexibleDocumentCreateItemRequest'
import { FlexibleDocumentCreateTemplateRequest }       from '@/requests/flexibleDocument/FlexibleDocumentCreateTemplateRequest'
import { FlexibleDocumentUpdateComponentRequest }      from '@/requests/flexibleDocument/FlexibleDocumentUpdateComponentRequest'
import { FlexibleDocumentUpdateItemOrderRequest }      from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'
import { FlexibleDocumentMetaPatchRequest }            from '@/requests/flexibleDocument/FlexibleDocumentMetaPatchRequest'
import { FlexibleDocumentUpdateExportSettingsRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateExportSettingsRequest'
import { CommentResource, ICommentResource }           from '@/models/comments/CommentResource'
import { AuditResource, IAuditResource }               from '@/models/audits/AuditResource'
import { FlexibleDocumentUpdateStatusRequest }         from '@/requests/flexibleDocument/FlexibleDocumentUpdateStatusRequest'
import { FlexibleDocumentReviewStatusRequest }         from '@/requests/flexibleDocument/FlexibleDocumentReviewStatusRequest'

export class FlexibleDocumentService extends BaseService {

  private readonly project_id: number

  constructor({ project_id }: { project_id: number }) {
    super(`projects/${project_id}/flexible_document`)
    this.project_id = project_id
  }

  private getDocumentId() {
    const path = window.location.pathname
    const elems = path.split('/')
    const documentId = sessionStorage.getItem('currentDocumentId')

    if (elems.length >= 4 && elems[1] === 'projects' && elems[3] === 'flexible-document') {
      return elems[4]
    }
    return documentId
  }

  public async create({ body }: { body: FlexibleDocumentCreateTemplateRequest }): Promise<DetailResponse<FlexibleDocumentResource>> {
    const { data } = await this.client.post<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/create`, body)
    return {
      ...data,
      data: new FlexibleDocumentResource(data.data, this.project_id)
    }
  }

  public async get(): Promise<DetailResponse<FlexibleDocumentResource>> {
    const documentId = this.getDocumentId()
    const { data } = await this.client.get<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}s/${documentId}`)
    return {
      ...data,
      data: new FlexibleDocumentResource(data.data, this.project_id)
    }
  }

  public async patch({ uuid, body }: { uuid: string, body: FlexibleDocumentUpdateRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}s/${uuid}`, body)
    return data
  }

  public async updateExportSettings({ blacklist: elements }: FlexibleDocumentUpdateExportSettingsRequest): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const { data } = await this.client.post<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/black_list`, { elements })
    return data
  }

  public async patchStatus({ body }: { body: FlexibleDocumentUpdateStatusRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const documentId = this.getDocumentId()
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}s/${documentId}/status`, body.getRequestData())
    return data
  }

  public async reviewStatus({ body }: { body: FlexibleDocumentReviewStatusRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const documentId = this.getDocumentId()
    if (documentId) {
      const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/${documentId}/status/feedback`, body)
      return data
    }
    else {
      const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/status/feedback`, body)
      return data
    }
  }

  public async getElement(uuid: string): Promise<DetailResponse<FlexibleDocumentItemResource>> {
    const documentId = this.getDocumentId()
    const url = `${this.namespace}s/${documentId}/element/${uuid}`
    
    const { data } = await this.client.get<DetailResponse<FlexibleDocumentItemResource>>(url)
    data.data = convertToFlexibleDocumentItem(data.data, this.project_id)
    return data
  }

  public async patchComponent({ uuid, body}: { uuid: string, body: FlexibleDocumentUpdateComponentRequest }): Promise<DetailResponse<FlexibleDocumentComponentCollectionResource>> {
    const documentId = this.getDocumentId()
    const url = `${this.namespace}s/${documentId}/element/${uuid}`
    
    const { data } = await this.client.patch<DetailResponse<FlexibleDocumentComponentCollectionResource>>(url, body)
    return data
  }

  public async patchElementOrder({ body }: { body: FlexibleDocumentUpdateItemOrderRequest }): Promise<FlexibleDocumentItemCollectionResource[]> {
    const documentId = this.getDocumentId()
    const { data } = await this.client.patch<FlexibleDocumentItemCollectionResource[]>(`${this.namespace}s/${documentId}/chapter`, body)
    return data.map((item) => convertToFlexibleDocumentCollectionItem(item, this.project_id))
  }

  public async postElement({ body }: { body: FlexibleDocumentCreateItemRequest }): Promise<DetailResponse<FlexibleDocumentItemCollectionResource>> {
    const { data } = await this.client.post<DetailResponse<FlexibleDocumentItemCollectionResource>>(`${this.namespace}`, body)
    
    data.data = convertToFlexibleDocumentCollectionItem(data.data, this.project_id)
    return data
  }

  public async getDropdownOptions(): Promise<DetailResponse<FlexibleDocumentDropdownOptionResource[]>> {
    const { data: elements } = await this.client.get(`${this.namespace}/elements`)
    return { data: elements.data.map((item: IFlexibleDocumentDropdownOptionResource) => new FlexibleDocumentDropdownOptionResource(item)) }
  }

  public async uploadFile(form: FormData): Promise<DetailResponse<{ image_url: string }>> {
    const documentId = this.getDocumentId()

    const { data } = await this.client.post(`${this.namespace}s/${documentId}/upload`, form)
    return data
  }

  public async getMeta(): Promise<IndexResponse<FlexibleDocumentMetaSectionResource>> {
    const { data } = await this.client.get<IndexResponse<IFlexibleDocumentMetaSectionResource>>(`${this.namespace}/meta`)
    return {
      ...data,
      data: data.data.map((s: IFlexibleDocumentMetaSectionResource) => new FlexibleDocumentMetaSectionResource(s))
    }
  }

  public async patchMeta({ form }: { form: FlexibleDocumentMetaPatchRequest }): Promise<DetailResponse<FlexibleDocumentMetaResource>> {
    const documentId = this.getDocumentId()
    
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentMetaResource>>(`${this.namespace}s/${documentId}/meta`, form)
    return {
      ...data,
      data: new FlexibleDocumentMetaResource(data.data)
    }
  }

  public async getComments(params?: Dictionary<any>): Promise<IndexResponse<CommentResource>> {
    const documentId = this.getDocumentId()
    const url = documentId ? `${this.namespace}s/${documentId}/comments` : `${this.namespace}/comments`

    const { data } = await this.client.get(url, { params })
    return {
      ...data,
      data: data.data.map((comment: ICommentResource) => new CommentResource(comment))
    }
  }

  public async getAudit(): Promise<IndexResponse<AuditResource>> {
    const documentId = this.getDocumentId()
    const base = `projects/${this.project_id}/flexible_documents`
    const url = documentId ? `${base}/${documentId}/audit` : `${this.namespace}/audit`

    const { data } = await this.client.get(url)
    return {
      ...data,
      data: data.data.map((audit: IAuditResource) => new AuditResource(audit))
    }
  }

  private async documentList() {
    const list = [
      {id: 1, selected: false, parent_id: null, title: 'Test document 1', modified: '16-08-2022', modified_by: 'Slawek Z', status: 'Draft', archived: false },
      {id: 2, selected: false, parent_id: null, title: 'Test document 2', modified: '17-08-2022', modified_by: 'Slawek Z', status: 'Draft', archived: false},
      {id: 3, selected: false, parent_id: null, title: 'Test document 3', modified: '18-08-2022', modified_by: 'Slawek Z', status: 'Draft', archived: false},
      {id: 4, selected: false, parent_id: null, title: 'Test document 4', modified: '19-08-2022', modified_by: 'Slawek Z', status: 'Draft', archived: false}
    ]
    return { data: list }
  }

  public async getDocumentsList() {
    const { data } = await this.documentList()
    // const { data } = await this.client.get(`${this.namespace}/all-documents`)
    return {data}
  }

  public async downloadPolicyPdf(url: any) {
    const resp = await this.client.get(url, { responseType: 'blob' })

    const { data } = resp
    return data
  }
}
