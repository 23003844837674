import { BaseService } from '@/services/base'

import { convertToFlexibleDocumentCollectionItem, convertToFlexibleDocumentItem } from '@/helpers/FlexibleDocument'

import { FlexibleDocumentResource, IFlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentItemResource }                        from '@/models/flexibleDocument/FlexibleDocumentItemResource'
import { FlexibleDocumentItemCollectionResource }              from '@/models/flexibleDocument/FlexibleDocumentItemCollectionResource'
import {
  FlexibleDocumentDropdownOptionResource,
  IFlexibleDocumentDropdownOptionResource
}                                                              from '@/models/flexibleDocument/FlexibleDocumentDropdownOptionResource'
import { FlexibleDocumentComponentCollectionResource }         from '@/models/flexibleDocument/FlexibleDocumentComponentCollectionResource'
import {
  FlexibleDocumentMetaSectionResource,
  IFlexibleDocumentMetaSectionResource
}                                                              from '@/models/flexibleDocument/FlexibleDocumentMetaSectionResource'
import {
  FlexibleDocumentMetaResource,
  IFlexibleDocumentMetaResource
}                                                              from '@/models/flexibleDocument/flexibleDocumentMetaResource'

import { FlexibleDocumentUpdateRequest }               from '@/requests/flexibleDocument/FlexibleDocumentUpdateRequest'
import { FlexibleDocumentCreateItemRequest }           from '@/requests/flexibleDocument/FlexibleDocumentCreateItemRequest'
import { FlexibleDocumentCreateTemplateRequest }       from '@/requests/flexibleDocument/FlexibleDocumentCreateTemplateRequest'
import { FlexibleDocumentUpdateComponentRequest }      from '@/requests/flexibleDocument/FlexibleDocumentUpdateComponentRequest'
import { FlexibleDocumentUpdateItemOrderRequest }      from '@/requests/flexibleDocument/FlexibleDocumentUpdateItemOrderRequest'
import { FlexibleDocumentMetaPatchRequest }            from '@/requests/flexibleDocument/FlexibleDocumentMetaPatchRequest'
import { FlexibleDocumentUpdateExportSettingsRequest } from '@/requests/flexibleDocument/FlexibleDocumentUpdateExportSettingsRequest'
import { CommentResource, ICommentResource }           from '@/models/comments/CommentResource'
import { AuditResource, IAuditResource }               from '@/models/audits/AuditResource'
import { FlexibleDocumentUpdateStatusRequest }         from '@/requests/flexibleDocument/FlexibleDocumentUpdateStatusRequest'
import { FlexibleDocumentReviewStatusRequest }         from '@/requests/flexibleDocument/FlexibleDocumentReviewStatusRequest'

export class MultipleDocumentsService extends BaseService {

  private readonly project_id: number

  constructor({ project_id }: { project_id: number }) {
    super(`projects/${project_id}/flexible_documents`)
    this.project_id = project_id
  }

  private getDocumentId() {
    const path = window.location.pathname
    const elems = path.split('/')
    // const documentId = sessionStorage.getItem('currentDocumentId')

    if (elems.length >= 4 && elems[1] === 'projects' && elems[3] === 'flexible-document') {
      return elems[4]
    }
    return null    
  }

  public async create({ body }: { body: any }) {
    const { data } = await this.client.post(`${this.namespace}/create`, body)
    return {
      ...data,
      data: new FlexibleDocumentResource(data.data, this.project_id)
    }
  }

  public async get(documentId: string): Promise<DetailResponse<FlexibleDocumentResource>> {
    const { data } = await this.client.get<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/${documentId}`)
    return {
      ...data,
      data: new FlexibleDocumentResource(data.data, this.project_id)
    }
  }

  public async patch({ uuid, body }: { uuid: string, body: FlexibleDocumentUpdateRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const documentId = this.getDocumentId()
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/${documentId}/element/${uuid}`, body)
    return data
  }

  public async updateExportSettings({ blacklist: elements }: FlexibleDocumentUpdateExportSettingsRequest): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const documentId = this.getDocumentId()    
    const { data } = await this.client.post<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/${documentId}/black_list`, { elements })
    return data
  }

  public async patchStatus({ body }: { body: FlexibleDocumentUpdateStatusRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/status`, body.getRequestData())
    return data
  }

  public async reviewStatus({ body }: { body: FlexibleDocumentReviewStatusRequest }): Promise<DetailResponse<IFlexibleDocumentResource>> {
    const documentId = this.getDocumentId()
    if (documentId) {
      const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/${documentId}/status/feedback`, body)
      return data
    }
    else {
      const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentResource>>(`${this.namespace}/status/feedback`, body)
      return data
    }

  }

  public async getElement(uuid: string): Promise<DetailResponse<FlexibleDocumentItemResource>> {
    const documentId = this.getDocumentId()
    const url = `${this.namespace}/${documentId}/element/${uuid}`
    
    const { data } = await this.client.get<DetailResponse<FlexibleDocumentItemResource>>(url)
    data.data = convertToFlexibleDocumentItem(data.data, this.project_id)
    return data
  }

  public async patchComponent({ uuid, body }: { uuid: string, body: FlexibleDocumentUpdateComponentRequest }): Promise<DetailResponse<FlexibleDocumentComponentCollectionResource>> {
    const { data } = await this.client.patch<DetailResponse<FlexibleDocumentComponentCollectionResource>>(`${this.namespace}/${uuid}`, body)
    return data
  }

  public async patchElementOrder({ body }: { body: FlexibleDocumentUpdateItemOrderRequest }): Promise<FlexibleDocumentItemCollectionResource[]> {
    const documentId = this.getDocumentId()
    const { data } = await this.client.patch<FlexibleDocumentItemCollectionResource[]>(`${this.namespace}/${documentId}/chapter`, body)
    return data.map((item) => convertToFlexibleDocumentCollectionItem(item, this.project_id))
  }

  public async postElement({ body }: { body: FlexibleDocumentCreateItemRequest }, templateId: any): Promise<DetailResponse<FlexibleDocumentItemCollectionResource>> {
    const { data } = await this.client.post<DetailResponse<FlexibleDocumentItemCollectionResource>>(`${this.namespace}/${templateId}`, body)
    data.data = convertToFlexibleDocumentCollectionItem(data.data, this.project_id)
    return data
  }

  public async getDropdownOptions(): Promise<DetailResponse<FlexibleDocumentDropdownOptionResource[]>> {
    const { data: elements } = await this.client.get(`${this.namespace}/elements`)
    return { data: elements.data.map((item: IFlexibleDocumentDropdownOptionResource) => new FlexibleDocumentDropdownOptionResource(item)) }
  }

  public async uploadFile(form: FormData): Promise<DetailResponse<{ image_url: string }>> {
    const { data } = await this.client.post(`${this.namespace}/upload`, form)
    return data
  }

  public async getMeta(): Promise<IndexResponse<FlexibleDocumentMetaSectionResource>> {
    const documentId = this.getDocumentId()

    const url = documentId ? `${this.namespace}/${documentId}/meta` : `${this.namespace}/meta`

    const { data } = await this.client.get<IndexResponse<IFlexibleDocumentMetaSectionResource>>(url)

    return {
      ...data,
      data: data.data.map((s: IFlexibleDocumentMetaSectionResource) => new FlexibleDocumentMetaSectionResource(s))
    }
  }

  public async patchMeta({ form }: { form: FlexibleDocumentMetaPatchRequest }): Promise<DetailResponse<FlexibleDocumentMetaResource>> {
    const { data } = await this.client.patch<DetailResponse<IFlexibleDocumentMetaResource>>(`${this.namespace}/meta`, form)
    return {
      ...data,
      data: new FlexibleDocumentMetaResource(data.data)
    }
  }

  public async getComments(params?: Dictionary<any>): Promise<IndexResponse<CommentResource>> {
    const { data } = await this.client.get(`${this.namespace}/comments/`, { params })
    return {
      ...data,
      data: data.data.map((comment: ICommentResource) => new CommentResource(comment))
    }
  }

  public async getAudit(): Promise<IndexResponse<AuditResource>> {
    const { data } = await this.client.get(`${this.namespace}/audit`)
    return {
      ...data,
      data: data.data.map((audit: IAuditResource) => new AuditResource(audit))
    }
  }

  public async toggleArchive(body: any) {
    const { data } = await this.client.patch(`${this.namespace}/archive`, body)
    return { data }
  }

  public async updateDocumentTitle(templateId: number, uuid: string, title: string) {
    const { data } = await this.client.patch(`${this.namespace}/${templateId}/element/${uuid}`, { title })
    return { data }
  }

  public async createNewDocument(body: any) {
    const { data } = await this.client.post(`${this.namespace}/create`, body)
    return { data }
  }

  public async getDocumentsList(folder_id: number | string) {
    const { data } = await this.client.get(`${this.namespace}/folders/${folder_id}/documents`)
    return {data}
  }
  
  public async getFolders() {
    const { data } = await this.client.get(`${this.namespace}/folders`)
    return { data }
  }
  
  public async createFolder(body: any) {
    const { data } = await this.client.post(`${this.namespace}/folders`, body)
    return { data }
  }
  
  public async moveFolder(body: any) {
    const { data } = await this.client.patch(`${this.namespace}/folders/move`, body)
    return { data }
  }
  
  public async moveItems(body: any) {
    const { data } = await this.client.patch(`${this.namespace}/move`, body)
    return { data }
  }
  
  public async moveDocuments(body: any) {
    const { data } = await this.client.patch(`${this.namespace}/move_documents`, body)
    return { data }
  }
  
  public async renameFolder(body: any, folder_id: number) {
    const { data } = await this.client.patch(`${this.namespace}/folders/${folder_id}/rename`, body)
    return { data }
  }
  
  public async deleteFolders(body: any) {
    const { data } = await this.client.post(`${this.namespace}/folders/delete`, body)
    return { data }
  }

  public async getAllDocuments() {
    const { data } = await this.client.get(`${this.namespace}/all`)
    return { data }
  }

  public async download(url: any) {
    const resp = await this.client.get(url, { responseType: 'blob' })

    const { data } = resp
    return data
  }

  public async getFolders2(project_id: number) {
    return {
      data: [
        {
          id: 1,
          parent_id: null,
          project_id: 6,
          title: 'All Documents',
          archived: false,
          updated_at: '13-04-2023 15:01:05'
        },
        {
          id: 2,
          project_id: 6,
          parent_id: 1,
          title: 'First sub folder',
          archived: true,
          updated_at: '13-04-2023 16:01:05'
        },
        {
          id: 3,
          project_id: 6,
          parent_id: 1,
          title: 'Second sub folder',
          archived: false,
          updated_at: '17-04-2023 15:01:05'
        },
        {
          id: 4,
          project_id: 6,
          parent_id: 3,
          title: 'Second level sub folder 1',
          archived: false,
          updated_at: '13-03-2023 15:01:05'
        },
        {
          id: 5,
          project_id: 6,
          parent_id: 3,
          title: 'Second level sub folder 2',
          archived: false,
          updated_at: '05-04-2023 17:22:05'
        },
        {
          id: 6,
          project_id: 6,
          parent_id: 4,
          title: 'Test third level 1',
          archived: false,
          updated_at: '05-04-2023 17:22:05'
        },
        {
          id: 7,
          project_id: 6,
          parent_id: 4,
          title: 'Test third level 1',
          archived: false,
          updated_at: '05-04-2023 17:22:05'
        },
      ]
    }
  }

}
