// import 'babel-polyfill'
import './component-hooks'

import '@/assets/sass/bootstrap.scss'
import '@/assets/sass/index.scss'


import Vue from 'vue'
import App from '@/views/App.vue'
import store from '@/store/index'
import router from '@/router/index'
import { sync } from 'vuex-router-sync'

sync(store, router)

// Add all icons globally
import { COMPONENTS } from '@/components/icons/index.ts'
for (const index of Object.keys(COMPONENTS)) {
  Vue.component(`${index}Icon`, COMPONENTS[index])
}

// Add all spinners globally
import { SPINNERS } from '@/components/spinners/index.ts'
for (const index of Object.keys(SPINNERS)) {
  Vue.component(`${index}`, SPINNERS[index])
}

import { BASE_COMPONENTS } from '@/components/proposal/create/baseComponents/index.ts'
for (const index of Object.keys(BASE_COMPONENTS)) {
  Vue.component(index, BASE_COMPONENTS[index])
}

// Comment component needs to be global because it invokes itself
import Comment from '@/components/comments/Comment.vue'
import SimpleComment from '@/components/comments/SimpleComment.vue'
import CreateProposal from '@/components/proposal/create/Index.vue'
import ProposalIndex from '@/components/proposal/detail/Index.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import Chapter from '@/views/dashboard/projects/detail/flexible-document/partials/Chapter.vue'
import ProposalCard from '@/components/tiles/ProposalCard.vue'

Vue.component('Comment', Comment)
Vue.component('SimpleComment', SimpleComment)
Vue.component('CreateProposal', CreateProposal)
Vue.component('ProposalIndex', ProposalIndex)
Vue.component('SmallLoader', SmallLoader)
Vue.component('Chapter', Chapter)
Vue.component('ProposalCard', ProposalCard)

import VueI18n from 'vue-i18n'
import { LANGUAGES } from '@/lang/index'
Vue.use(VueI18n)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import { MsalPlugin, MsalPluginOptions } from './plugins/msal-plugin';

const options: MsalPluginOptions = {
  clientId: "04d5e38e-5f68-45e1-8a6c-eeb7469ae181",
  loginAuthority: "https://corlyticsstaging.b2clogin.com/corlyticsstaging.onmicrosoft.com/B2C_1A_TRUSTFRAMEWORKEXTENSIONS_SEAMLESSMIGRATION/",
  passwordAuthority: "https://corlyticsstaging.b2clogin.com/corlyticsstaging.onmicrosoft.com/B2C_1A_TRUSTFRAMEWORKEXTENSIONS_SEAMLESSMIGRATION/",
  knownAuthority: "corlyticsstaging.b2clogin.com",
  redirectUri: window.location.origin
};
Vue.use(new MsalPlugin(), options);

import { tooltip }  from '@/directives/tooltip'
import { clickoutside }  from '@/directives/clickoutside'
import { visibleposition } from '@/directives/visibleposition'
Vue.directive('tooltip', tooltip)
Vue.directive('clickoutside', clickoutside)
Vue.directive('visibleposition', visibleposition)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: LANGUAGES
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
